import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import styles from './ClipFiltersSideBar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faChevronCircleUp, faHistory, faSpinner, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import Button from 'src/Widgets/common/basicElements/Button/Button';
import LabeledSelect from 'src/Widgets/common/basicElements/LabeledSelect/LabeledSelect';
import DateRangePicker from 'src/Widgets/common/basicElements/DateRangePicker/DateRangePicker';
import { MentionsVisualizerTypes } from '../../MentionsVisualizer.types';
import useFilterChange from '../../hooks/useFilterChange';
import { dateToString, stringToHash, trackEvent} from 'src/Widgets/common/helpers';
import { deleteProfileFromReduxAndBackend, getProfileGroups } from 'src/redux/actions/actions.profileGroups';
import OptionSwitch from 'src/Widgets/common/basicElements/OptionSwitch/OptionSwitch';
import APIProfiles from 'src/API/APIProfiles';
import SelectAsync from 'src/Widgets/common/basicElements/SelectAsync/SelectAsync';
import config from 'src/config/config';
import { deleteGroup, getGroups } from 'src/redux/actions/actions.channelGroups';
import { ChannelGroup } from 'src/Widgets/ChannelGroupsNext/types/types';
import LoadingSpinner from 'src/Widgets/common/basicElements/LoadingSpinner/LoadingSpinner';

const ClipFiltersSideBar = ({
    isToggleChannels,
    isToggleProfileGroups,
    setIsToggleChannels,
    setIsToggleProfileGroups,
    filter,
    setFilter,
    data,
    searchGroup,
    setModalType,
    applyFilters,
    loading,
    setLoading,
    fetchCountsHandler,
    fetchSearchClips,
    setIsSelectChannelsShown,
    isSelectChannelsShown,
    isSearch,
    isEdited,
    isRecent,
    isEditorial,
    activeSearchHistoryItemId,
    setActiveSearchHistoryItemId,
    tryQuery,
    setIsDateRangeSet,
    queryIssue,
    setIsQueryVizOverlayActive,
    setShowQueryVis
}: MentionsVisualizerTypes.ClipFiltersSideBarProps) => {
    const chGroups = useAppSelector((state) => state.channelGroupReducers) || [];
    const profileGroups = useAppSelector((state) => state.profileGroups.data) || [];
    const selectedChannels = useAppSelector(
        (state) => state.channelGroupsWidgetReducer.selectedChannelIds
    ) || [];
    const matomo = useAppSelector((state) => state.matomo);

    const { t } = useTranslation();
    const isEmbed = window.location.href.includes('embed');

    // handle filter change event and set filter state accordingly
    const { handleFilterChange } = useFilterChange({
        filter,
        setFilter,
        fetchCountsHandler
    });

    const [showStatusFilter, setShowStatusFilter] = useState(false);
    const [isSearchHistoryCollapsed, setIsSearchHistoryCollapsed] = useState(true);
    const [disabledItems, setDisabledItems] = useState<number[]>([]);
    const [deleteQueue, setDeleteQueue] = useState<number[]>([]);
    const [isProcessingQueue, setIsProcessingQueue] = useState(false);
    const [isDeletingAll, setIsDeletingAll] = useState(false);


    const dispatch = useAppDispatch();

    const languages = Array.from(new Set(data.map((item: any) => item.language))).filter(
        (lang: any) => lang !== undefined
    );
    const mediatypes = Array.from(new Set(data.map((item: any) => item.mediatype)));

    // clear icon component for each filter
    const ClearIcon = ({filterName, customStyle}: any) => {
        if (filterName === 'dateRange') {
            return (
                <FontAwesomeIcon
                    icon={faTimes}
                    className={
                        styles.filterRemove + ' ' + customStyle
                    }
                    onClick={() => {
                        setFilter({
                            ...filter,
                            dateRange: {
                                startDate: null,
                                endDate: null,
                                customRange: ''
                            }
                        });
                    }}
                />
            );
        }
        return (
            <FontAwesomeIcon
                icon={faTimes}
                className={
                    styles.filterRemove + ' ' + customStyle
                }
                onClick={() => {
                    setFilter({ ...filter, [filterName]: '' });
                }}
            />
        );
    };

    const handleRowItemDelete = async (
        e: SyntheticEvent<HTMLOrSVGElement>,
        profile: any
      ) => {
        // Prevent further propagation if the item is already disabled.
        if (disabledItems.includes(profile.id)) return;
        
        e.stopPropagation();
        
        // add the profile.id to the disabledItems state.
        setDisabledItems((prev) => [...prev, profile.id]);
        setFilter((prev) => ({ ...prev, search: '' }));
        
        // Find the relevant channel group.
        const searchChannelGroups = chGroups.filter(
          (group: ChannelGroup) =>
            group.title.startsWith(config.searchChannelGroupTitle) &&
            !group.title.endsWith('tryQuery')
        );
        
        const searchChannelGroup = searchChannelGroups.find(
          (group: ChannelGroup) =>
            group.title === `${config.searchChannelGroupTitle + profile.title}` ||
            group.title ===
              `${config.searchChannelGroupTitle + stringToHash(profile.title)}`
        );
        
        // Perform deletion.
        const deleteRes = await dispatch(
          deleteProfileFromReduxAndBackend(searchProfileGroup.id, profile.id, [4433])
        );
        
        if (deleteRes.rc === 0) {
          if (searchChannelGroup) {
            handleDeleteChannelGroupClick(searchChannelGroup.id);
          }
        }else if(deleteRes.rc === 4433){
            setModalType('alreadyDeletedProfileUpdate')
        }
        
        // remove the profile.id from disabledItems.
        setDisabledItems((prev) => prev.filter((item) => item !== profile.id));
        return searchChannelGroup ? searchChannelGroup.id : null
      };


      const handleHistoryRowClick = async (profile: any) => {
        // If the profile is already active, do nothing.
        if (profile.id === activeSearchHistoryItemId) {
          return;
        }
      
        setActiveSearchHistoryItemId(profile.id);
        const profileDetail = await APIProfiles.get(profile.id);
        
        setTimeout(() => {
          setIsSearchHistoryCollapsed(true);
        }, 400);
        
        const queryLang = Object.keys(profileDetail.data.queries).find((key) => key !== '**') || "**";
        const standardQuery = profileDetail.data.queries[queryLang]?.standardQuery?.query;
        
        setFilter({
          ...filter,
          search: standardQuery || '',
        });
      };
       
      

    const processQueue = useCallback(async () => {
        if (isProcessingQueue || deleteQueue.length === 0) return;

        setIsProcessingQueue(true);
        while (deleteQueue.length > 0) {
            const itemId = deleteQueue.shift();
            await dispatch(deleteGroup(itemId));
        }
        setIsProcessingQueue(false);
    }, [deleteQueue, isProcessingQueue, dispatch]);

    const toggleSwitchHandler = (e: any) => {
        setIsToggleChannels(e.target.checked);
    };

    useEffect(() => {
        processQueue();
    }, [deleteQueue, processQueue]);

    const handleDeleteChannelGroupClick = (itemId: number) => {
        setDeleteQueue((prevQueue) => [...prevQueue, itemId]);
    };

    useEffect(() => {
        if(isEditorial) {
            setShowStatusFilter(false);
            setFilter(prevFilter => ({ ...prevFilter, status: null }));
        }
    }, [isToggleProfileGroups]);

    useEffect(() => {
        profileGroups.length === 0 && dispatch(getProfileGroups());
    }, []);

    useEffect(() => {
        if(filter.dateRange?.startDate && filter.dateRange?.endDate){
        setIsDateRangeSet(true);    
        fetchCountsHandler(
            dateToString(filter.dateRange?.startDate),
            dateToString(filter.dateRange?.endDate)
        );
    }else{
        setIsDateRangeSet(false);
    }
    }, [filter.dateRange]);

    useEffect(() => {
       if(isEmbed){
        fetchCountsHandler(
            dateToString(filter.dateRange?.startDate),
            dateToString(filter.dateRange?.endDate)
        );
       }
    }, [isToggleProfileGroups, isEmbed]);

    useEffect(() => {
        if (tryQuery?.query && isSearch) {
            applyFilters(filter);
            if (isSearch && filter.dateRange) {
                fetchSearchClips(filter.search, filter.dateRange);
            }
        }
    }, [tryQuery?.query]);

    useEffect(() => {
        if (filter.dateRange?.startDate && filter.dateRange?.endDate) {
            setIsDateRangeSet(true);
        } else {
            setIsDateRangeSet(false);
        }
        isEditorial && setShowStatusFilter(false)
    }, [filter.dateRange]);

    useEffect(() => {
        if(isEditorial && data.length > 0){
            setShowStatusFilter(true)
        }
    }, [data])

    useEffect(() => {
        if(isEditorial){
            setShowStatusFilter(false)
        }
    }, [filter.profileGroup])


    const hasQueryLanguages = Array.from(new Set(data.flatMap((item: MentionsVisualizerTypes.Clip) => {
        const basicNotifications = item.basicNotifications || [];
        return basicNotifications.flatMap((notification: any) => notification.queries.map((query: any) => `${query.lang}`));
    })));

    const searchGroupExists = profileGroups?.some(
        (group: any) => group.title === config.searchQueryGroupTitle
    );

    const searchProfileGroup = searchGroupExists
        ? profileGroups?.find(
              (group: any) => group.title === config.searchQueryGroupTitle
          )
        : null;

  return (
      <div
          className={`${styles.clipFilterSelectContainer} ${styles.clipFilterSelectContainerHidden}`}
      >
          <div className={styles.filterItemContainer}>
              <div
                  style={{
                      margin: 0,
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.4rem',
                      zIndex: 9999
                  }}
              >
                  <div
                      data-testid="date-range-select"
                      style={{
                          position: 'relative',
                          maxWidth: '14.5rem'
                      }}
                  >
                      {/*@ts-ignore*/}
                      <LabeledSelect
                          name="dateRange"
                          label={t('Custom Date Range')}
                          value={
                              filter.dateRange?.customRange && {
                                  value: filter.dateRange.customRange,
                                  label: filter.dateRange.customRange
                              }
                          }
                          onChange={handleFilterChange}
                          className={styles.clipFilterSelect}
                          options={
                              isEditorial
                                  ? [
                                        { value: t('Today'), label: t('Today') },
                                        { value: t('Yesterday'), label: t('Yesterday') },
                                        {
                                            value: t('Last 2 days'),
                                            label: t('Last 2 days')
                                        }
                                    ]
                                  : [
                                        { value: t('Today'), label: t('Today') },
                                        { value: t('Yesterday'), label: t('Yesterday') },
                                        {
                                            value: t('Last 7 days'),
                                            label: t('Last 7 days')
                                        },
                                        {
                                            value: t('Last 30 days'),
                                            label: t('Last 30 days')
                                        },
                                        {
                                            value: t('This month'),
                                            label: t('This month')
                                        },
                                        { value: t('Last month'), label: t('Last month') }
                                    ]
                          }
                      />
                      {filter.dateRange && filter.dateRange.customRange !== '' && (
                          <ClearIcon
                              filterName="dateRange"
                              customStyle={styles.customRemoveIcon}
                          />
                      )}
                  </div>

                  {/*@ts-ignore*/}
                  <DateRangePicker
                      onChange={(dateRange: any) => {
                          setFilter({
                              ...filter,
                              dateRange: {
                                  startDate: dateRange?.[0],
                                  endDate: dateRange?.[1],
                                  customRange: 'Custom Range'
                              }
                          });
                      }}
                      value={[filter.dateRange?.startDate, filter.dateRange?.endDate]}
                      maxDate={new Date()}
                      minDate={
                          isEditorial
                              ? new Date(new Date().setDate(new Date().getDate() - 2))
                              : undefined
                      }
                      className={styles.clipFilterDateRangePicker}
                      archive={false}
                      allowClear={
                          !!(filter.dateRange?.startDate || filter.dateRange?.endDate)
                      }
                  />
              </div>
              {!isRecent && !isEdited && (
                  <div
                      className={styles.channelSelectionContainer}
                      style={{
                          position: 'relative'
                      }}
                  >
                      <OptionSwitch
                          option1={t('by channels')}
                          option2={t('by channel group')}
                          onChange={toggleSwitchHandler}
                          checkedValue={isToggleChannels}
                          dataTestIdOption1="channelsSwitch"
                          dataTestIdOption2="channelGroupsSwitch"
                      />

                      {isToggleChannels && (
                          <div
                              style={{
                                  margin: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '0.4rem',
                                  zIndex: 9999
                              }}
                              data-testid="channelGroupSelectBox"
                          >
                              {/*@ts-ignore*/}
                              <SelectAsync
                                  name="selectedChannelGroup"
                                  //label={t('Channel Group')}
                                  value={filter.selectedChannelGroup}
                                  onChange={handleFilterChange}
                                  placeholder={t('Select Channel Group')}
                                  className={styles.clipFilterSelectChannelGroup}
                                  options={chGroups
                                      .filter(
                                          (group: any) =>
                                              group.channels.length > 0 &&
                                              !group?.title.includes(
                                                  config.searchChannelGroupTitle
                                              )
                                      )
                                      .map((group: any) => ({
                                          value: group,
                                          label: group.title
                                      }))}
                                  isDisabled={
                                      !filter.dateRange?.startDate ||
                                      !filter.dateRange?.endDate
                                  }
                              />
                              {filter.selectedChannelGroup &&
                                  filter.selectedChannelGroup !== null && (
                                      <ClearIcon
                                          filterName="selectedChannelGroup"
                                          customStyle={
                                              isToggleChannels
                                                  ? styles.groupSelectRemoveIcon
                                                  : null
                                          }
                                      />
                                  )}
                          </div>
                      )}
                      {!isToggleChannels && (
                          <Button
                              type="primary"
                              data-testid="channelSelectionButton"
                              disabled={
                                  !filter.dateRange?.startDate ||
                                  !filter.dateRange?.endDate
                              }
                              onClick={() => {
                                  setIsSelectChannelsShown(!isSelectChannelsShown);
                              }}
                          >
                              {!isSelectChannelsShown ? (
                                  <>
                                      {selectedChannels?.length > 0 &&
                                          t('Selected Channels')}{' '}
                                      {selectedChannels?.length > 0
                                          ? ` (${selectedChannels?.length})`
                                          : t('Select Channels')}
                                  </>
                              ) : (
                                  <>
                                      <FontAwesomeIcon icon={faBackward} size="1x" />{' '}
                                      {t('Back To Results')}{' '}
                                  </>
                              )}
                          </Button>
                      )}
                  </div>
              )}

              {
                  <div
                      className={styles.groupSelectionContainer}
                      style={
                          isEditorial
                              ? {
                                    marginBlockStart: '-0.4rem'
                                }
                              : {}
                      }
                  >
                      {isEditorial && (
                          <>
                              <OptionSwitch
                                  option2={t('All Groups')}
                                  option1={t('by profile group')}
                                  onChange={() =>
                                      setIsToggleProfileGroups(!isToggleProfileGroups)
                                  }
                                  checkedValue={!isToggleProfileGroups}
                              />
                              {/*@ts-ignore*/}
                              <SelectAsync
                                  name="profileGroup"
                                  label={t('Profile Group')}
                                  instanceId={'profileGroupDropdown'}
                                  value={
                                      filter.profileGroup?.label?.length > 17
                                          ? {
                                                value: filter.profileGroup.value,
                                                label: `${filter.profileGroup?.label?.substring(
                                                    0,
                                                    17
                                                )}...`
                                            }
                                          : filter.profileGroup
                                  }
                                  onChange={handleFilterChange}
                                  className={styles.clipFilterSelect}
                                  isDisabled={
                                      !filter.dateRange?.startDate ||
                                      !filter.dateRange?.endDate ||
                                      !isToggleProfileGroups
                                  }
                                  options={profileGroups
                                      .sort((a: any, b: any) => {
                                          if (a.sharedBy && !b.sharedBy) return 1;
                                          if (!a.sharedBy && b.sharedBy) return -1;
                                          return a.title.localeCompare(b.title);
                                      })
                                      .map((group: any) => ({
                                          value: { id: group.id, title: group.title },
                                          label: group.sharedBy
                                              ? `[SH] ${
                                                    group.title.length > 21
                                                        ? `${group.title.substring(
                                                              0,
                                                              21
                                                          )}...`
                                                        : group.title
                                                }`
                                              : `${
                                                    group.title.length > 21
                                                        ? `${group.title.substring(
                                                              0,
                                                              21
                                                          )}...`
                                                        : group.title
                                                }`,
                                          profiles: group.profiles,
                                          title: group.sharedBy
                                              ? `[SH] ${group.title}`
                                              : group.title,
                                          sharedBy: group.sharedBy
                                      }))}
                              />
                              {filter.profileGroup && (
                                  <ClearIcon
                                      filterName="profileGroup"
                                      customStyle={
                                          isToggleProfileGroups
                                              ? styles.groupSelectRemoveIcon
                                              : null
                                      }
                                  />
                              )}
                          </>
                      )}

                      {!isSearch && !isEditorial && (
                          <div
                              style={{
                                  position: 'relative',
                                  width: '14.5rem'
                              }}
                              data-testid={'profileGroupDropdown'}
                          >
                              {/*@ts-ignore*/}
                              <LabeledSelect
                                  name="profileGroup"
                                  label={t('Profile Group')}
                                  instanceId={'profileGroupDropdown'}
                                  value={
                                      filter.profileGroup?.label?.length > 17
                                          ? {
                                                value: filter.profileGroup.value,
                                                label: `${filter.profileGroup?.label?.substring(
                                                    0,
                                                    17
                                                )}...`
                                            }
                                          : filter.profileGroup
                                  }
                                  onChange={handleFilterChange}
                                  className={styles.clipFilterSelect}
                                  isDisabled={
                                      !filter.dateRange?.startDate ||
                                      !filter.dateRange?.endDate ||
                                      !isToggleProfileGroups
                                  }
                                  options={profileGroups
                                      .sort((a: any, b: any) => {
                                          if (a.sharedBy && !b.sharedBy) return 1;
                                          if (!a.sharedBy && b.sharedBy) return -1;
                                          return a.title.localeCompare(b.title);
                                      })
                                      .map((group: any) => ({
                                          value: { id: group.id, title: group.title },
                                          label: group.sharedBy
                                              ? `[SH] ${
                                                    group.title.length > 21
                                                        ? `${group.title.substring(
                                                              0,
                                                              21
                                                          )}...`
                                                        : group.title
                                                }`
                                              : `${
                                                    group.title.length > 21
                                                        ? `${group.title.substring(
                                                              0,
                                                              21
                                                          )}...`
                                                        : group.title
                                                }`,
                                          profiles: group.profiles,
                                          title: group.sharedBy
                                              ? `[SH] ${group.title}`
                                              : group.title,
                                          sharedBy: group.sharedBy
                                      }))}
                              />
                              {filter.profileGroup && (
                                  <ClearIcon filterName="profileGroup" />
                              )}
                          </div>
                      )}
                  </div>
              }
              {isEditorial && (
                  <>
                      <div
                          style={{
                              position: 'relative',
                              width: '14.5rem'
                          }}
                      >
                          {/*@ts-ignore*/}
                          <LabeledSelect
                              name="status"
                              label={t('Filter by Status')}
                              value={filter.status}
                              onChange={handleFilterChange}
                              className={styles.clipFilterSelect}
                              placeholder={`${
                                  !showStatusFilter && data.length > 0
                                      ? t('Reapply selection')
                                      : t('Select')
                              }...`}
                              options={[
                                  { value: 'todo', label: t('Todo') },
                                  { value: 'locked', label: t('Taken') },
                                  { value: 'done', label: t('Done') },
                                  { value: 'rejected', label: t('Rejected') },
                                  { value: 'dup', label: t('Duplicate') }
                              ]}
                              isDisabled={
                                  !filter.dateRange?.startDate ||
                                  !filter.dateRange?.endDate ||
                                  (isEditorial &&
                                      (filter.profileGroup === '' ||
                                          (isToggleProfileGroups &&
                                              filter.profileGroup === null))) ||
                                  !showStatusFilter
                              }
                          />
                          {filter.status && <ClearIcon filterName="status" />}
                      </div>
                  </>
              )}

              {isEditorial && (
                  <>
                      <div
                          style={{
                              position: 'relative',
                              width: '14.5rem'
                          }}
                      >
                          {/*@ts-ignore*/}
                          <LabeledSelect
                              name="queryLanguage"
                              label={t('Query Language')}
                              value={filter.queryLanguage}
                              onChange={handleFilterChange}
                              className={styles.clipFilterSelect}
                              placeholder={`${
                                  !showStatusFilter && data.length > 0
                                      ? t('Reapply selection')
                                      : !(
                                            filter.profileGroup?.profiles?.some(
                                                (profile: any) => {
                                                    return profile.super === 1;
                                                }
                                            ) || !isToggleProfileGroups
                                        )
                                      ? t('Not available')
                                      : t('Select')
                              }...`}
                              options={Array.from(
                                  new Set(
                                      data.flatMap(
                                          (item: MentionsVisualizerTypes.Clip) => {
                                              const basicNotifications =
                                                  item.basicNotifications || [];
                                              return basicNotifications.flatMap(
                                                  (notification: any) =>
                                                      notification.queries.map(
                                                          (query: any) => `${query.lang}`
                                                      )
                                              );
                                          }
                                      )
                                  )
                              )
                                  //exclude **
                                  .filter((lang: any) => lang !== '**')
                                  .map((queryTitle: any) => ({
                                      value: queryTitle,
                                      label: queryTitle
                                  }))
                                  .filter((query: any) => {
                                      return (
                                          !filter.profileQuery ||
                                          filter.profileQuery.lang === '**' ||
                                          query.label.endsWith(
                                              `${filter.profileQuery.lang}`
                                          )
                                      );
                                  })}
                              isDisabled={
                                  !filter.dateRange?.startDate ||
                                  !data.length ||
                                  !hasQueryLanguages.length ||
                                  !filter.dateRange?.endDate ||
                                  (isEditorial &&
                                      (filter.profileGroup === '' ||
                                          (isToggleProfileGroups &&
                                              filter.profileGroup === null))) ||
                                  !showStatusFilter ||
                                  !(
                                      filter.profileGroup?.profiles?.some(
                                          (profile: any) => {
                                              return profile.super === 1;
                                          }
                                      ) || !isToggleProfileGroups
                                  ) ||
                                  data.every((item: MentionsVisualizerTypes.Clip) =>
                                      item.basicNotifications?.every(
                                          (notification: any) =>
                                              notification.queries.every(
                                                  (query: any) => query.lang === '**'
                                              )
                                      )
                                  )
                              }
                          />
                          {filter.queryLanguage && (
                              <ClearIcon filterName="queryLanguage" />
                          )}
                      </div>
                  </>
              )}
              {isEditorial && (
                  <>
                      <div
                          style={{
                              position: 'relative',
                              width: '14.5rem'
                          }}
                      >
                          {/*@ts-ignore*/}
                          <LabeledSelect
                              name="profileQuery"
                              label={t('Profile Query')}
                              value={filter.profileQuery}
                              onChange={handleFilterChange}
                              className={styles.clipFilterSelect}
                              placeholder={`${
                                  !showStatusFilter && data.length > 0
                                      ? t('Reapply selection')
                                      : !(
                                            filter.profileGroup?.profiles?.some(
                                                (profile: any) => {
                                                    return profile.super === 1;
                                                }
                                            ) || !isToggleProfileGroups
                                        )
                                      ? t('Not available')
                                      : t('Select')
                              }...`}
                              options={Array.from(
                                  new Set(
                                      data.flatMap(
                                          (item: MentionsVisualizerTypes.Clip) => {
                                              const basicNotifications =
                                                  item.basicNotifications || [];
                                              return basicNotifications.flatMap(
                                                  (notification: any) =>
                                                      notification.queries.map(
                                                          (query: any) => ({
                                                              id: query.id,
                                                              value: `${query.lang}|${query.title}`,
                                                              label: `${query.lang} | ${query.title}`,
                                                              lang: query.lang
                                                          })
                                                      )
                                              );
                                          }
                                      )
                                  )
                              )
                                  .map((query: any) => ({
                                      id: query.id,
                                      value: query.value,
                                      label: query.label,
                                      lang: query.lang
                                  }))
                                  .filter(
                                      (query, index, self) =>
                                          index ===
                                          self.findIndex((q) => q.id === query.id)
                                  )
                                  .filter(
                                      (query: any) =>
                                          !filter.queryLanguage ||
                                          filter.queryLanguage.value === '**' ||
                                          query.label.startsWith(
                                              `${filter.queryLanguage.value} |`
                                          )
                                  )}
                              isDisabled={
                                  !filter.dateRange?.startDate ||
                                  !filter.dateRange?.endDate ||
                                  (isEditorial &&
                                      (filter.profileGroup === '' ||
                                          (isToggleProfileGroups &&
                                              filter.profileGroup === null))) ||
                                  !data.length ||
                                  !hasQueryLanguages.length ||
                                  !showStatusFilter ||
                                  //!filter.queryLanguage ||
                                  !(
                                      filter.profileGroup?.profiles?.some(
                                          (profile: any) => {
                                              return profile.super === 1;
                                          }
                                      ) || !isToggleProfileGroups
                                  )
                              }
                          />
                          {filter.profileQuery && <ClearIcon filterName="profileQuery" />}
                      </div>
                  </>
              )}
          </div>

          {!isRecent && !isEdited && !isSearch && (
              <div className={styles.filterItemContainer}>
                  {/*@ts-ignore*/}
                  <LabeledSelect
                      name="profile"
                      label={t('Profile')}
                      value={filter.profile}
                      onChange={handleFilterChange}
                      className={styles.clipFilterSelect}
                      isDisabled={!filter.profileGroup}
                      options={
                          // @ts-ignore
                          filter.profileGroup.profiles?.map((profile: any) => {
                              return {
                                  value: {
                                      id: profile.id,
                                      title: profile.title
                                  },
                                  label: profile.title
                              };
                          }) ||
                          profileGroups.flatMap((group: any) => {
                              return group.profiles.map((profile: any) => {
                                  return {
                                      value: {
                                          id: profile.id,
                                          title: profile.title
                                      },
                                      label: profile.title
                                  };
                              });
                          })
                      }
                  />
                  {filter.profile && filter.profile !== '' && (
                      <ClearIcon filterName="profile" />
                  )}
              </div>
          )}
          {!isRecent && !isEdited && !isSearch && (
              <div className={styles.filterItemContainer}>
                  {/*@ts-ignore*/}
                  <LabeledSelect
                      name="language"
                      label={t('Language')}
                      value={filter.language}
                      onChange={handleFilterChange}
                      className={styles.clipFilterSelect}
                      options={languages.map((language) => ({
                          value: language,
                          label: language
                      }))}
                      isDisabled={
                          !filter.dateRange?.startDate || !filter.dateRange?.endDate
                      }
                  />
                  {filter.language && filter.language !== '' && (
                      <ClearIcon filterName="language" />
                  )}
              </div>
          )}
          {!isRecent && !isEdited && !isSearch && (
              <div className={styles.filterItemContainer}>
                  {/*@ts-ignore*/}
                  <LabeledSelect
                      name="mediaType"
                      label={t('Media Type')}
                      value={filter.mediaType}
                      onChange={handleFilterChange}
                      className={styles.clipFilterSelect}
                      isDisabled={
                          !filter.dateRange?.startDate || !filter.dateRange?.endDate
                      }
                      options={mediatypes.map((mediatype) => ({
                          value: mediatype,
                          label: mediatype
                      }))}
                  />
                  {filter.mediaType && filter.mediaType !== '' && (
                      <ClearIcon filterName="mediaType" />
                  )}
              </div>
          )}

          {
              <div
                  className={styles.searchButtonContainer}
                  style={
                      isSearch
                          ? {
                                marginBlockStart: '1.4rem',
                                marginInlineStart: '6rem'
                            }
                          : {
                                marginInlineStart: '6rem'
                            }
                  }
              >
                  {isSearch && searchGroup && (
                      <div className={styles.searchHistoryContainer}>
                          <div
                              className={styles.searchProfileHistoryContainer}
                              data-testid="search-history-container"
                          >
                              {searchGroup.profiles.length > 0 &&
                                  !isSearchHistoryCollapsed && (
                                      <Button
                                          type="danger"
                                          data-testid="deleteAllSearchHistoryButton"
                                          disabled={
                                              isDeletingAll || disabledItems.length > 0
                                          }
                                          onClick={async (e: any) => {
                                              trackEvent(
                                                  matomo,
                                                  'MVSearchHistory',
                                                  'clear all button clicked for deleting search history'
                                              );
                                              setIsDeletingAll(true);
                                              try {
                                                  for (const profile of searchGroup.profiles) {
                                                      await handleRowItemDelete(
                                                          e,
                                                          profile
                                                      );
                                                  }
                                              } finally {
                                                  setIsDeletingAll(false);
                                              }
                                          }}
                                      >
                                          {isDeletingAll ? (
                                              <span
                                                  className={
                                                      styles.deleteAllButtonContainer
                                                  }
                                              >
                                                  <FontAwesomeIcon
                                                      icon={faSpinner}
                                                      spin
                                                  />
                                                  {t('Clearing')}
                                              </span>
                                          ) : (
                                              t('Clear All')
                                          )}
                                      </Button>
                                  )}
                              {searchGroup.profiles
                                  .slice(
                                      0,
                                      isSearchHistoryCollapsed
                                          ? 0
                                          : searchGroup.profiles.length
                                  )
                                  .map((profile: any, index: number) => {
                                      return (
                                          <div
                                              key={profile.id}
                                              data-testid={`search-profile-history-item-${index}`}
                                              className={
                                                  activeSearchHistoryItemId === profile.id
                                                      ? styles.searchProfileHistoryItemActive
                                                      : styles.searchProfileHistoryItem
                                              }
                                              style={
                                                  disabledItems.includes(profile.id) ||
                                                  isDeletingAll
                                                      ? { pointerEvents: 'none' }
                                                      : {}
                                              }
                                              onClick={() => {
                                                  handleHistoryRowClick(profile);
                                              }}
                                          >
                                              <span
                                                  className={
                                                      styles.searchProfileHistoryItemText
                                                  }
                                                  title={profile.title}
                                                  data-testid={`search-history-item-${profile.title}`}
                                                  style={
                                                      disabledItems.includes(
                                                          profile.id
                                                      ) ||
                                                      isDeletingAll ||
                                                      isProcessingQueue
                                                          ? {
                                                                pointerEvents: 'none',
                                                                opacity: 0.5
                                                            }
                                                          : {}
                                                  }
                                              >
                                                  {profile.title.length > 15
                                                      ? `${profile.title.substring(
                                                            0,
                                                            15
                                                        )}...`
                                                      : profile.title}
                                                  {/* {!filter.selectedChannelGroup?.value &&
                                                      !selectedChannels.length &&
                                                      ` (${
                                                          searchClipCounts[profile.id] ||
                                                          0
                                                      })`} */}
                                                  <span
                                                      className={styles.deleteWrapper}
                                                      onClick={async (
                                                          e: SyntheticEvent<HTMLOrSVGElement>
                                                      ) => {
                                                          const res =
                                                              await handleRowItemDelete(
                                                                  e,
                                                                  profile
                                                              );
                                                          trackEvent(
                                                              matomo,
                                                              'MVSearchHistory',
                                                              `delete temp. profile from search`,
                                                              {
                                                                  profileId: profile.id,
                                                                  channelGroup: res
                                                              }
                                                          );
                                                      }}
                                                  >
                                                      {disabledItems.includes(
                                                          profile.id
                                                      ) ? (
                                                          <FontAwesomeIcon
                                                              icon={faSpinner}
                                                              spin
                                                              data-testid={`search-profile-history-item-spinner-${index}`}
                                                              className={
                                                                  activeSearchHistoryItemId ===
                                                                  profile.id
                                                                      ? styles.searchProfileHistoryItemRemoveActive
                                                                      : styles.searchProfileHistoryItemRemove
                                                              }
                                                          />
                                                      ) : (
                                                          <FontAwesomeIcon
                                                              icon={faTrashAlt}
                                                              data-testid={`search-profile-history-item-remove-${index}`}
                                                              className={
                                                                  activeSearchHistoryItemId ===
                                                                  profile.id
                                                                      ? styles.searchProfileHistoryItemRemoveActive
                                                                      : styles.searchProfileHistoryItemRemove
                                                              }
                                                          />
                                                      )}
                                                  </span>
                                              </span>
                                          </div>
                                      );
                                  })}
                          </div>
                      </div>
                  )}
                  {searchProfileGroup &&
                      searchProfileGroup.profiles.length > 0 &&
                      isSearch &&
                      !tryQuery && (
                          <Button
                              type="primary"
                              title={t('Search History')}
                              data-testid="search-history-button"
                              onClick={() => {
                                  setIsSearchHistoryCollapsed(!isSearchHistoryCollapsed);
                                  //fetchCountsHandler(filter.dateRange.startDate, filter.dateRange.endDate);
                              }}
                          >
                              {isSearchHistoryCollapsed ? (
                                  <span>
                                      <FontAwesomeIcon icon={faHistory} /> {t('History')}
                                  </span>
                              ) : (
                                  <span>
                                      <FontAwesomeIcon icon={faChevronCircleUp} />{' '}
                                      {t('History')}
                                  </span>
                              )}
                          </Button>
                      )}
                  <Button
                      type="secondary"
                      data-testid="apply-filters-button"
                      disabled={
                          !filter.dateRange?.startDate ||
                          !filter.dateRange?.endDate ||
                          loading ||
                          (!isSearch && !isEditorial && !filter.profileGroup) ||
                          (isEditorial &&
                              (filter.profileGroup === '' ||
                                  (isToggleProfileGroups &&
                                      filter.profileGroup === null))) ||
                          (isSearch && !filter.search)
                      }
                      onClick={() => {
                        setLoading(true);
                          if (queryIssue) {
                              return;
                          }
                          applyFilters(filter);
                          setShowStatusFilter(true);
                          setIsSelectChannelsShown(false);
                          if (isSearch && filter.dateRange) {
                              fetchSearchClips(
                                  filter.search,
                                  filter.dateRange,
                                  queryIssue
                              );
                              setIsSearchHistoryCollapsed(true);
                              if (setIsQueryVizOverlayActive) {
                                  setIsQueryVizOverlayActive(false);
                                  setShowQueryVis(false);
                              }
                          }
                      }}
                      btnClass={
                          isSearch ? styles.clipSearchButton : styles.clipApplyButton
                      }
                  >
                      {isRecent || isEdited ? t('Apply') : t('Search')}
                  </Button>
              </div>
          }
      </div>
  );
};

export default ClipFiltersSideBar;
